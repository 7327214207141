// This template creates two cols and renders them inside a container and row (as seen on /legal/ pages)
// Include these in first meta data object in pages JSON:
//
// "template": "splitTemplate",
// "leftColWidth": "3", <-- bootstrap col value
// "rightColWidth": "9" <-- bootstrap col value
//
// Components default to right side.
// Add  "aboveSplit" to component to render above the row
// Add "leftSide": true to component to render in left col
// add "rightSide": true to component to render in right col
// add "belowSplit": true to component to render under split

import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
/* eslint-enable */

import PropTypes from 'prop-types';
import styled from 'styled-components';
import Dynamic from '../components/Dynamic';
import Layout from '../components/layout';
import SEO from '../components/seo';
import marketo from '../utils/marketo.munchkin';
import bodyTagId from '../utils/bodyTagId';
import killServiceWorkers from '../utils/killServiceWorkers';
import generateFAQSchema from '../utils/generateFAQSchema';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const SplitContainer = styled.div``;

const LeftColumn = styled.div`
  @media screen and (max-width: 992px) {
    padding: 0 0px !important;
  }
`;

const ContentHolder = styled.div`
  padding: 60px 0;
  .target-class-leftTitleWithText {
    padding: 0 !important;
    .container {
      padding: 0 !important;
    }
  }
`;

const splitTemplate = ({ data }) => {
  const aboveSplit = [];
  const leftSide = [];
  const rightSide = [];
  const belowSplit = [];

  JSON.parse(data.dynamicPage.content).forEach((item) => {
    if (item.aboveSplit) {
      aboveSplit.push(item);
    } else if (item.leftSide) {
      leftSide.push(item);
    } else if (item.belowSplit) {
      belowSplit.push(item);
    } else {
      rightSide.push(item);
    }
  });

  const { dynamicPage } = data;
  // Set leftBackgroundColor and rightBackgroundColor to the same value in JSON
  const leftBackgroundColor = dynamicPage.leftBackgroundColor
    ? dynamicPage.leftBackgroundColor
    : '#fff';
  const rightBackgroundColor = dynamicPage.rightBackgroundColor
    ? dynamicPage.rightBackgroundColor
    : '#fff';
  // if each Col has a different backgroundColor, include containerClass = 'container-fluid' in the JSON --- still messing with this concept
  const containerClass = dynamicPage.containerClass
    ? dynamicPage.containerClass
    : 'container';

  const rightColId = dynamicPage.rightColId
    ? dynamicPage.rightColId
    : 'SplitContainerRightCol';

  const paddingLeftCol = dynamicPage.paddingLeftCol
    ? dynamicPage.paddingLeftCol
    : '0px 30px 0px 0px';
  const hideLeftOnMobile = dynamicPage.hideLeftOnMobile
    ? dynamicPage.hideLeftOnMobile
    : false;
  const hideClasses = hideLeftOnMobile ? 'd-none d-lg-block' : 'd-block';
  const leftStickyCol = dynamicPage.leftSticky ? 'sticky-top' : '';

  // Google Structured Data
  (function GoogleStructuredData() {
    useEffect(() => {
      if (dynamicPage.googleFaqSchema) {
        generateFAQSchema();
      }
    }, []);
  })();

  return (
    <Layout
          navbarType={data.dynamicPage.navbarType}
          navbarBackground={data.dynamicPage.navbarBackground}>
      {bodyTagId(data.dynamicPage.bodyTagId)}
      <SEO
        title={data.dynamicPage.title}
        description={data.dynamicPage.description}
        url={data.dynamicPage.url}
        canonical={data.dynamicPage.canonical}
        image={data.dynamicPage.seoPreviewImg}
        noindex={data.dynamicPage.noindex}
      />
      {/* Render any content above split.  For example hero, CTAs, etc.. */}
      {/* aboveSplit key in JSON */}
      {aboveSplit.map((item) => (
        <div key={pmUuid()}>
          {Dynamic[item.type] ? Dynamic[item.type](item) : ''}
        </div>
      ))}
      <SplitContainer>
        <div className={`${containerClass}`}>
          <div className="row">
            <LeftColumn
              id="SplitContainerLeftCol"
              className={`col-lg-${data.dynamicPage.leftColWidth} ${hideClasses}`}
              style={{
                backgroundColor: leftBackgroundColor,
                padding: paddingLeftCol
              }}
            >
              <ContentHolder
                className={`text-center text-lg-left ${leftStickyCol}`}
              >
                {leftSide.map((item) => (
                  <React.Fragment key={pmUuid()}>
                    {Dynamic[item.type] ? Dynamic[item.type](item) : ''}
                  </React.Fragment>
                ))}
              </ContentHolder>
            </LeftColumn>
            <div
              id={rightColId}
              className={`col-lg-${data.dynamicPage.rightColWidth}`}
              style={{ backgroundColor: rightBackgroundColor }}
              // Scrollspy for LeftNav.jsx
              data-spy="scroll"
              data-target="#pm-sidenav"
              data-offset="0"
            >
              <ContentHolder>
                {rightSide.map((item) => (
                  <div key={pmUuid()}>
                    {Dynamic[item.type] ? Dynamic[item.type](item) : ''}
                  </div>
                ))}
              </ContentHolder>
            </div>
          </div>
        </div>
      </SplitContainer>
      {belowSplit.map((item) => (
        <div key={pmUuid()}>
          {Dynamic[item.type] ? Dynamic[item.type](item) : ''}
        </div>
      ))}
      {/* Below renders the footer */}
      {JSON.parse(data.globalValues.content).map((item) => {
        if (item.type === 'footer') {
          return <div key={pmUuid()}>{Dynamic.footer(item)}</div>;
        }
        return <React.Fragment key={pmUuid()} />;
      })}
      {marketo()}
      {killServiceWorkers()}
    </Layout>
  );
};

export default splitTemplate;

export const query = graphql`
  query ($id: String!) {
    dynamicPage(id: { eq: $id }) {
      id
      url
      content
      description
      title
      canonical
      noindex
      leftColWidth
      rightColWidth
      leftBackgroundColor
      rightBackgroundColor
      googleFaqSchema
      containerClass
      navbarType
      navbarBackground
      paddingLeftCol
      hideLeftOnMobile
      rightColId
      bodyTagId
      seoPreviewImg
      leftSticky
    }
    globalValues {
      content
    }
  }
`;

splitTemplate.propTypes = {
  data: PropTypes.shape({
    dynamicPage: PropTypes.shape({
      content: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      url: PropTypes.string,
      canonical: PropTypes.string,
      navbarType: PropTypes.string,
      navbarBackground: PropTypes.string,
      noindex: PropTypes.bool,
      leftBackgroundColor: PropTypes.string,
      rightBackgroundColor: PropTypes.string,
      googleFaqSchema: PropTypes.bool,
      containerClass: PropTypes.string,
      rightColId: PropTypes.string,
      paddingLeftCol: PropTypes.string,
      hideLeftOnMobile: PropTypes.bool,
      leftColWidth: PropTypes.string,
      rightColWidth: PropTypes.string,
      seoPreviewImg: PropTypes.string,
      leftSticky: PropTypes.bool
    }),
    globalValues: PropTypes.shape({
      content: PropTypes.string
    })
  }).isRequired
};
